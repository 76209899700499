import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { typography, mediaQueries as mq } from 'src/styles'
import PropTypes from 'prop-types'

const HeroHeadline = styled.h1 `
  ${typography.heading1}
  margin: 0;
`

const Headline = styled.h2 `
  font-size: ${typography.fontSizeExtraLarge3};
  margin: 0;
  font-weight: 700;

  ${ mq.largeAndUp } {
   font-size: ${typography.fontSizeExtraLarge5}
  }
`

const ShortRule = styled.hr `
  width: 35px;
  border-color: currentColor;
  border-width: 3px;
  text-align: left;
  margin: ${({isHero}) => isHero ? `30px 0` : `10px 0`};

  ${ mq.largeAndUp } {
    width: 70px;
    border-width: ${({isHero}) => isHero ? `4px` : `5px`};
    margin: ${({isHero}) => isHero ? `40px 0` : `20px 0`};
  }
`

const Paragraph = styled.p `
  ${ typography.bodyLarge };
  font-weight: 500;
  margin: 0;
  width: 80%;

  ${ mq.largeAndUp } {
    width: 50%;
  }
`

const Slug = styled.div `
  font-weight: 500;
  margin-bottom: 5px;
  font-size: ${typography.fontSizeMedium};

  ${ mq.mediumAndUp } {
    margin-bottom: 10px;
    font-size: ${typography.fontSizeExtraLarge2}
  }

`
export default function ShortRuleHeadline({
  slug,
  isHero = false,
  headline,
  text
}) {

  return (
    <Fragment>
      {slug && <Slug>{slug}</Slug>}
      {isHero && <HeroHeadline>{headline}</HeroHeadline>}
      {!isHero && <Headline>{headline}</Headline>}
      {text && (
        <Fragment>
          <ShortRule isHero={isHero} />
          <Paragraph>{text}</Paragraph>
        </Fragment>
      )}
    </Fragment>
  )
}

ShortRuleHeadline.propTypes = {
  slug: PropTypes.string,
  headline: PropTypes.string,
  isHero: PropTypes.bool,
  text: PropTypes.string,
}
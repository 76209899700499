import React, { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colors, helpers } from 'src/styles';
import ReactPlayer from 'react-player';
import Image from 'src/components/Image';
import Video from 'src/components/Video';
import Link from 'src/components/Link';
import { gsap } from 'gsap';
import IconArrow from 'src/svg/icon-arrow.svg';
import PageContext from 'src/context/PageContext';

const PlayerWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const VideoWrapper = styled.div`
  padding-top: ${`${(9 / 16) * 100}%`};
  position: relative;
`;
const PosterWrapper = styled.div`
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;
const PosterVideo = styled(Video)`
  width: 100%;
`;
const PosterImage = styled(Image)`
  display: block;
  margin: 0;
`;
const VideoDescription = styled.div`
  ${helpers.responsiveStyles('padding', 40, 32, 24, 18)}
  background-color:  currentColor;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Eyebrow = styled.div`
  font-size: 13px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.03em;
  color: ${colors.white};
  margin: 0 0 8px;
`;
const Title = styled.h5`
  margin: 0 0 8px;
  color: ${colors.white};
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;
const VideosLink = styled(Link)`
  font-size: 13px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.03em;
  color: ${colors.white};
  letter-spacing: 0.25px;

  &:hover {
    text-decoration: underline;
  }
`;
const Arrow = styled(IconArrow)`
  margin-left: 5px;
  height: 9px;
  width: 10px;
`;
export default function VideoPlayer({
  videoId,
  eyebrow,
  poster,
  posterType = 'image',
  title,
  videosRoute = false,
}) {
  const [videoOpen, setVideoOpen] = useState(false);
  const videoWrap = useRef(null);
  const { lang } = useContext(PageContext);

  const onClick = () => {
    setVideoOpen(true);
    gsap.to(videoWrap.current, {
      duration: 0.5,
      autoAlpha: 0,
      ease: 'power2.out',
    });
  };

  const renderPoster = () => {
    return (
      <PosterWrapper ref={videoWrap} onClick={onClick}>
        {posterType === 'image' && <PosterImage image={poster} />}
        {posterType === 'video' && <PosterVideo video={{ file: poster }} />}
      </PosterWrapper>
    );
  };

  const renderDescription = () => {
    if (eyebrow || title) {
      return (
        <VideoDescription>
          <Eyebrow>{eyebrow}</Eyebrow>
          <Title>{title}</Title>
          <div style={{ flex: 1 }} />
          {!videosRoute && (
            <VideosLink to={'/resources/videos'}>
              {lang === 'es' ? 'Ver todos los videos' : 'View all videos'} <Arrow />
            </VideosLink>
          )}
        </VideoDescription>
      );
    }
  };

  const playerConfig = {
    youtube: {
      preload: false,
      playerVars: {
        color: 'white',
        controls: 1,
        disablekb: 1,
        modestbranding: 1,
      },
    },
    vimeo: {
      preload: false,
    },
  };

  if (!videoId) {
    return false;
  }

  return (
    <PlayerWrapper>
      <VideoWrapper>
        <StyledReactPlayer
          url={
            /^https?:\/\//.test(videoId)
              ? videoId
              : 'https://player.vimeo.com/video/' + videoId
          }
          playing={videoOpen}
          width="100%"
          height="100%"
          config={playerConfig}
          controls
        />
        {poster && renderPoster()}
      </VideoWrapper>
      {renderDescription()}
    </PlayerWrapper>
  );
}

VideoPlayer.propTypes = {
  videoId: PropTypes.string,
  eyebrow: PropTypes.string,
  posterType: PropTypes.string,
  title: PropTypes.string,
};

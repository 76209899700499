import React from 'react';
import styled from '@emotion/styled';
import Container from 'src/components/Container';
import { ResponsiveImage } from 'src/components/Image';
import Video from 'src/components/Video';
import ScrollEntrance from 'src/components/ScrollEntrance';
import ShortRuleHeadline from 'src/components/ShortRuleHeadline';
import { colors, mediaQueries as mq } from 'src/styles';
import PropTypes from 'prop-types';

const HeroWrapper = styled.div`
  position: relative;
`;
const Background = styled.div`
  background-color: ${colors.textColor};
  overflow: hidden;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
`;
const BackgroundVideo = styled(Video)`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;

  ${({ video }) =>
    video &&
    `
    background-color: ${colors.textColor};
  `}

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const BackgroundImage = styled(ResponsiveImage)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  > div {
    display: none;
  }
`;
const BackgroundOverlay = styled.div`
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 100%;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
`;
const Content = styled(Container)`
  color: ${colors.bgColor};
  display: flex;
  min-height: calc(100vh - 100px);
  height: 100%;
  position: relative;
  padding: 100px 0;
  align-items: center;
  z-index: 3;

  ${mq.largerAndUp} {
    min-height: 100vh;
  }
`;

const Hero = ({ slug, headline, text, image, video, type }) => (
  <HeroWrapper>
    <Background>
      {type === 'video' && <BackgroundVideo useFadeIn video={video} />}
      {type === 'image' && (
        <BackgroundImage
          image={image}
          objectFit="cover"
          animate={false}
          imgStyle={{ position: 'relative', display: 'block' }}
        />
      )}
      <BackgroundOverlay />
    </Background>
    <Content>
      <ScrollEntrance>
        <ShortRuleHeadline isHero slug={slug} headline={headline} text={text} />
      </ScrollEntrance>
    </Content>
  </HeroWrapper>
);

export default Hero;

Hero.propTypes = {
  slug: PropTypes.string,
  headline: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object,
  video: PropTypes.object,
  type: PropTypes.string,
};

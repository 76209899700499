import React, { useEffect, useState, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const StyledVideo = styled.video`
  transition: opacity 1s ease-in-out;
  width: 100%;
  ${ ({ hasPosterImage, useFadeIn, posterLoaded }) => hasPosterImage && useFadeIn && `
    opacity: ${posterLoaded}
  `}
  background-image: url(${ ({bgImgLoading}) => bgImgLoading });
  background-repeat: repeat-y;
  background-position: center;
  background-size: contain;
`

const Video = ({ video, className, useFadeIn = false, ...rest }) => {
  const [ref, inView] = useInView()
  const [posterLoaded, setPosterLoaded] = useState(0);
  const [playPromise, setPlayPromise] = useState(null);
  const videoEl = useRef(null);
  const videoUrl = typeof video.file === 'string' ? video.file : (video.file ? video.file.localFile.publicURL : '')
  const hasPosterImage = !!video.placeholder_image;
  const posterSource = hasPosterImage ? video.placeholder_image.localFile.publicURL : null;

  const { bgImgLoading } = useStaticQuery(
    graphql`
      query {
        bgImgLoading: file(relativePath: { eq: "image-loading.gif" }) {
          publicURL
        }
      }
    `
  )

  useEffect(() => {
    if(hasPosterImage){
      const img = new Image();
      img.onload = () => {
        setPosterLoaded(1)
      }
      img.src = posterSource;
    }
  }, [hasPosterImage, posterSource])

  useEffect(() => {
    if (inView) {
      const currentPlayPromise = videoEl.current.play();
      if (playPromise === null) {
        setPlayPromise(currentPlayPromise);
      }
    }

    if (!inView && playPromise !== null) {
      playPromise.then(() => {
        videoEl.current.pause();
      })
    }
  }, [inView, playPromise])

  return (
    <div ref={ref} className={className}>
      <StyledVideo
        {...rest}
        ref={videoEl}
        src={videoUrl}
        poster={posterSource}
        bgImgLoading={bgImgLoading.publicURL}
        preload="none"
        muted
        playsInline
        hasPosterImage={hasPosterImage}
        posterLoaded={posterLoaded}
        useFadeIn={useFadeIn}
        loop
      />
    </div>
  )
}

Video.propTypes = {
  video: PropTypes.shape({
    file: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]).isRequired,
    placeholder_image: PropTypes.object,
  }).isRequired,
}

export default Video
